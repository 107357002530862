<template>
  <div
    class="flex grow flex-col gap-y-1 overflow-y-auto border-r border-gray-200 bg-white px-2"
  >
    <SkeletonText v-if="loading" class="mt-8" />
    <template v-else>
      <div v-if="withClientSelect" class="flex h-16 shrink-0 items-center">
        <img
          v-if="clients.length <= 0 || !activeClient"
          class="h-8 w-auto"
          src="~/assets/img/logo-wide.png"
          alt="DatAds"
        />
        <SidebarClientSelect
          v-else
          :clients="clients"
          :active-client="activeClient"
        />
      </div>
      <div class="flex shrink-0 items-center px-2 pb-2">
        <h3
          :class="[
            'text-gray-400 text-xs',
            isSettings || isConnection ? 'mt-4' : 'mt-2',
          ]"
        >
          {{ curSection }}
        </h3>
      </div>
      <SidebarNav
        v-if="isAnalytics"
        :navigation="analyticsNavigation"
        :folders="getFolders(FolderCategory.analysis)"
        :can-manage="canManage"
        :reports="reports"
        :testing-log-reports="testingLogReports"
        :launch-reports="launchReports"
        has-topcreative-report
        has-comparison-report
        :has-testing-log-report="hasTestingLogReport"
        :has-launch-report="hasLaunchReport"
        @create:report="onCreateReport"
        @delete:report="onDeleteReport"
        @share:report="onShareReport"
        @create:testinglog:report="onCreateTestingLogReport"
        @delete:testinglog:report="onDeleteTestingLogReport"
        @share:testinglog:report="onShareTestingLogReport"
        @create:launch:report="onCreateLaunchReport"
        @delete:launch:report="onDeleteLaunchReport"
        @share:launch:report="onShareLaunchReport"
        @toggle:folder="onToggleFolder"
        @create:folder="onCreateFolder(FolderCategory.analysis)"
        @delete:folder="onDeleteFolder"
        @rename:folder="onRenameFolder"
        @duplicate:folder="onDuplicateFolder"
      />
      <SidebarNav
        v-else-if="isInspiration"
        :navigation="inspirationNavigation"
        :folders="getFolders(FolderCategory.inspiration)"
        :can-manage="canManage"
        :boards="inspirationBoards"
        :has-inspiration-board="hasInspirationBoard"
        @create:board="onCreateBoard"
        @delete:board="onDeleteBoard"
        @share:board="onShareBoard"
        @toggle:folder="onToggleFolder"
        @create:folder="onCreateFolder(FolderCategory.inspiration)"
        @delete:folder="onDeleteFolder"
        @rename:folder="onRenameFolder"
        @duplicate:folder="onDuplicateFolder"
      />
      <SidebarNavTagging v-else-if="isTagging" />
      <SidebarNavSettings v-else-if="isSettings || isConnection" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import {
  RocketLaunchIcon,
  HomeIcon,
  BeakerIcon,
} from "@heroicons/vue/24/outline";
import SidebarNavTagging from "./SidebarNavTagging.vue";
import SidebarNavSettings from "./SidebarNavSettings.vue";
import {
  AnyCreativeReport,
  CreativeReportType,
} from "~/types/AnyCreativeReport";
import { FolderCategory } from "~/types/FolderStructure";

defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const appUserHelpers = useAppUser();
const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const isAdmin = computed(() => appUserHelpers.isAdmin(appUserMe.value));
const isMember = computed(() => appUserHelpers.isMember(appUserMe.value));
const canManage = computed(() => isAdmin.value || isMember.value);

const { hasFeature } = useFeatureFlags();
const hasTestingLogReport = hasFeature("testing-log-as-report_2024-03-27");
const hasLaunchReport = hasFeature("launch-report_2024-08-20");
const hasLaunchCockpitFeature = hasFeature("launch-cockpit_2024-07-15");
const hasTaggingFeature = hasFeature("tagging_2024-09-02");
const hasInspirationBoard = hasFeature("inspiration-board_2024-01-01");

const route = useRoute();
const router = useRouter();

const isTagging = computed(() => useIsCurrentRoute("/tagging"));
const isInspiration = computed(() => useIsCurrentRoute("/inspiration-board"));
const isSettings = computed(() =>
  useIsCurrentRoute("/settings", {
    noMatchPaths: ["/settings/connections"],
  }),
);
const isConnection = computed(() =>
  useIsCurrentRoute("/settings/connections", { matchExact: true }),
);
const isAnalytics = computed(
  () =>
    useIsCurrentRoute("/home") ||
    useIsCurrentRoute("/creative-comparison") ||
    useIsCurrentRoute("/creative-library") ||
    useIsCurrentRoute("/creative-reporting") ||
    useIsCurrentRoute("/launch") ||
    useIsCurrentRoute("/testing-log"),
);
const curSection = computed(() => {
  if (isSettings.value || isConnection.value) {
    return "Settings";
  }
  if (isInspiration.value) {
    return "Inspiration";
  }
  if (isAnalytics.value) {
    return "Creative Analytics";
  }
  return "Tagging";
});
const withClientSelect = computed(
  () => isAnalytics.value || isTagging.value || isInspiration.value,
);

const hasLaunchCockpitReport = computed(() => {
  if (!hasLaunchCockpitFeature || activeClient.value == null) {
    return false;
  }
  const launchCockpitReportIdMaybe = activeClient.value.launchCockpitReport;
  if (launchCockpitReportIdMaybe == null) {
    return false;
  }
  const loadedReport = getLaunchReportsOfClient
    .value(activeClient.value.id)
    .find((report) => report.id === launchCockpitReportIdMaybe);
  return loadedReport != null;
});

const TOP_CREATIVE_DIGEST_FOLDER_NAME = "Top Creative Digest";
const folderStructureStore = useFolderStructureStore();
const getFolders = (category: FolderCategory) => {
  if (!activeClient.value) {
    return [];
  }
  return folderStructureStore
    .getFoldersOfClient(activeClient.value.id, category)
    .filter((folder) =>
      hasFeature("top-creative-digest_2024-11-17")
        ? true
        : folder.title !== TOP_CREATIVE_DIGEST_FOLDER_NAME,
    );
};

const creativeReportingStore = useCreativeReportingStore();
const { getReportsOfClient: getCreativeReportingReportsOfClient } = storeToRefs(
  creativeReportingStore,
);

const creativeComparisonStore = useCreativeComparisonStore();
const { getReportsOfClient: getCreativeComparisonReportsOfClient } =
  storeToRefs(creativeComparisonStore);

const inspirationBoardStore = useInspirationBoardStore();
const { getBoardsOfClient: getInspirationBoardsOfClient } = storeToRefs(
  inspirationBoardStore,
);

const testingLogStore = useTestingLogStore();
const { getReportsOfClient: getTestingLogReportsOfClient } =
  storeToRefs(testingLogStore);

const launchReportStore = useLaunchReportStore();
const { getReportsOfClient: getLaunchReportsOfClient } =
  storeToRefs(launchReportStore);

const clientStore = useClientStore();
const { activeClient, clients } = storeToRefs(clientStore);

const {
  deleteReport,
  getReportId,
  createReport,
  getReportByKey,
  copyShareLink,
} = useAnyCreativeReport();

const { createBoard, copyBoardShareLink, deleteBoard } = useInspirationBoard();

const {
  createReport: createTestingLogReport,
  deleteReport: deleteTestingLogReport,
  copyShareLink: copyTestingLogShareLink,
} = useTestingLog();

const {
  createReport: createLaunchReport,
  deleteReport: deleteLaunchReport,
  copyShareLink: copyLaunchReportShareLink,
} = useLaunchReport();

const reports = computed<AnyCreativeReport[]>(() => {
  if (!activeClient.value) {
    return [];
  }

  const anyCreativeReportingReport: AnyCreativeReport[] =
    getCreativeReportingReportsOfClient
      .value(activeClient.value.id)
      .map((report) => ({
        ...report,
        type: "topcreatives",
      }));

  const anyCreativeComparisonReport: AnyCreativeReport[] =
    getCreativeComparisonReportsOfClient
      .value(activeClient.value.id)
      .map((report) => ({
        ...report,
        type: "comparison",
      }));

  return [...anyCreativeReportingReport, ...anyCreativeComparisonReport];
});

const inspirationBoards = computed(() => {
  if (!activeClient.value) {
    return [];
  }

  return getInspirationBoardsOfClient.value(activeClient.value.id);
});

const testingLogReports = computed(() => {
  if (!activeClient.value) {
    return [];
  }

  return getTestingLogReportsOfClient.value(activeClient.value.id);
});

const launchReports = computed(() => {
  if (!activeClient.value || !hasLaunchCockpitReport.value) {
    return [];
  }

  return getLaunchReportsOfClient
    .value(activeClient.value.id)
    .filter((report) => report.id !== activeClient.value?.launchCockpitReport);
});

const analyticsNavigation = computed(() =>
  [
    {
      name: "Home",
      href: "/home",
      icon: HomeIcon,
      current: useIsCurrentRoute("/home"),
      indicatorNumber: 0,
      dataCy: "sidebar-home",
    },
    {
      name: "Launch Cockpit",
      href: "/launch/cockpit",
      icon: RocketLaunchIcon,
      current: useIsCurrentRoute("/launch/cockpit"),
      indicatorNumber: 0,
      dataCy: "sidebar-launch-cockpit",
    },
    {
      name: "Testing Log",
      href: "/testing-log/cockpit",
      icon: BeakerIcon,
      current: useIsCurrentRoute("/testing-log/cockpit"),
      indicatorNumber: 0,
      dataCy: "sidebar-testing-log-cockpit",
    },
  ]
    .filter(
      (item) => item.href !== "/launch/cockpit" || hasLaunchCockpitReport.value,
    )
    .filter(
      (item) =>
        item.href !== "/tagging/unlabeled" ||
        (hasTaggingFeature && canManage.value),
    ),
);

const inspirationNavigation = computed(() => [
  {
    name: "Getting Started",
    href: "/inspiration-board/getting-started",
    icon: HomeIcon,
    current: useIsCurrentRoute("/inspiration-board/getting-started"),
    indicatorNumber: 0,
    dataCy: "sidebar-inspiration-board-getting-started",
  },
]);

const onToggleFolder = async (folderId: number) => {
  const folderMaybe = folderStructureStore.getFolderById(folderId);
  if (!folderMaybe) {
    return;
  }
  await folderStructureStore.updateFolderMetadata({
    folderId,
    isOpen: !folderMaybe.isOpen,
  });
};

const onDeleteReport = async (report: {
  id: string;
  type: CreativeReportType;
}) => {
  if (!canManage.value) {
    return;
  }

  const reportId = getReportId(report.id);
  await deleteReport({ id: reportId, type: report.type });
};

const onCreateReport = async (dto: {
  type: CreativeReportType;
  folderId: number | null;
}) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createReport({
    type: dto.type,
    clientId: activeClient.value.id,
    folderId: dto.folderId,
  });
};

const onShareReport = (report: { id: string; type: CreativeReportType }) => {
  const reportMaybe = getReportByKey(report.id);
  if (reportMaybe) copyShareLink({ uuid: reportMaybe.uuid, type: report.type });
};

const onDeleteBoard = async (board: { id: string }) => {
  if (!canManage.value) {
    return;
  }

  const boardMaybe = inspirationBoardStore.getBoardByUuid(board.id);
  if (boardMaybe) {
    await deleteBoard(boardMaybe.id);
  }
};

const onCreateBoard = async (folderId: number | null = null) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createBoard({ clientId: activeClient.value.id, folderId });
};

const onShareBoard = (board: { id: string }) => {
  copyBoardShareLink(board.id);
};

const onDeleteTestingLogReport = async (report: { id: string }) => {
  if (!canManage.value) {
    return;
  }

  const reportMaybe = testingLogStore.getReportByUuid(report.id);
  if (reportMaybe) {
    await deleteTestingLogReport(reportMaybe.id);
  }
};

const onCreateTestingLogReport = async (folderId: number | null = null) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createTestingLogReport({
    clientId: activeClient.value.id,
    folderId,
  });
};

const onShareTestingLogReport = (report: { id: string }) => {
  copyTestingLogShareLink(report.id);
};

const onDeleteLaunchReport = async (report: { id: string }) => {
  if (!canManage.value) {
    return;
  }

  const reportMaybe = launchReportStore.getReportByUuid(report.id);
  if (reportMaybe) {
    await deleteLaunchReport(reportMaybe.id);
  }
};

const onCreateLaunchReport = async (folderId: number | null = null) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  await createLaunchReport({
    clientId: activeClient.value.id,
    folderId,
  });
};

const onShareLaunchReport = (report: { id: string }) => {
  copyLaunchReportShareLink(report.id);
};

const onCreateFolder = async (category: FolderCategory) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  const folderIdMaybe = await folderStructureStore.createFolder({
    clientId: activeClient.value.id,
    title: "New Folder",
    category,
  });

  if (folderIdMaybe != null && typeof folderIdMaybe !== "string") {
    await router.push({
      path: route.path,
      query: {
        ...route.query,
        mode: "rename-folder",
        folderId: folderIdMaybe.id,
      },
    });
  }
};

const onDeleteFolder = async (folderId: number) => {
  if (activeClient.value == null || !canManage.value) {
    return;
  }

  const confirmed = confirm(
    "Are you sure you want to delete this folder? This action cannot be undone.",
  );

  if (!confirmed) {
    return;
  }

  await folderStructureStore.deleteFolder({ folderId });
};

const onRenameFolder = async (folderId: number) => {
  if (!canManage.value) {
    return;
  }
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "rename-folder",
      folderId,
    },
  });
};

const onDuplicateFolder = async (folderId: number) => {
  if (!canManage.value) {
    return;
  }
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "duplicate-folder",
      folderId,
    },
  });
};
</script>
