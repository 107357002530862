<template>
  <Menu
    v-tooltip="'Switch to other client'"
    as="div"
    class="relative inline-block text-left w-full"
  >
    <div class="w-full">
      <MenuButton
        class="inline-flex w-full items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-100 hover:bg-gray-50 w-full"
        data-cy="sidebar-client-select-button"
      >
        <img
          class="object-contain inline-block h-6 w-6 rounded-md mr-1"
          :src="activeClient.displayImage"
          :alt="activeClient.displayName"
        />
        {{ activeClient.displayName }}
        <ChevronDownIcon
          class="-mr-1 h-5 w-5 text-gray-400 ml-auto"
          aria-hidden="true"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-[13rem] max-h-96 overflow-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        data-cy="sidebar-client-select-menu"
      >
        <div class="py-1">
          <MenuItem
            v-for="client in clients"
            :key="client.id"
            v-slot="{ active }"
          >
            <a
              href="#"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'inline-flex w-full px-3 py-2 text-sm gap-x-1',
              ]"
              @click.prevent="onSetActiveClient(client.id)"
            >
              <img
                class="object-contain inline-block h-6 w-6 rounded-md mr-1"
                :src="client.displayImage"
                :alt="client.displayName"
              />
              <div class="inline-flex items-center w-full">
                <p class="line-clamp-1">{{ client.displayName }}</p>
                <Loader
                  v-if="activatingClientId === client.id"
                  aria-hidden="true"
                  class="ml-2"
                />
              </div>
            </a>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <nuxt-link
              to="/settings/clients"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-3 py-2 text-sm',
              ]"
            >
              Manage
            </nuxt-link>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { PropType } from "vue";
import { SELECT_DROPDOWN_CLIENT_PRIORITY } from "~/stores/client";
import { Client } from "~/types/Client";

const { clients, activeClient } = defineProps({
  clients: {
    type: Array as PropType<Client[]>,
    required: true,
    default: () => [],
  },
  activeClient: {
    type: Object as PropType<Client>,
    required: true,
  },
});

const route = useRoute();
const router = useRouter();

const activatingClientId = ref<number | null>(null);

const onSetActiveClient = async (id: number) => {
  const clientStore = useClientStore();
  clientStore.setActiveClientId(id, SELECT_DROPDOWN_CLIENT_PRIORITY);
  const isOnHome = route.path.includes("/home");
  const isOnLaunchCockpit = route.path.includes("/launch/cockpit");
  const isOnTestingLogCockpit = route.path.includes("/testing-log/cockpit");
  // If we are on the creative/launch cockpit page, we need to keep the query params
  // Otherwise, the ui state and the backend fetch task might get out of sync
  const query = isOnHome || isOnLaunchCockpit ? { ...route.query } : {};
  const path = isOnLaunchCockpit
    ? "/launch/cockpit"
    : isOnTestingLogCockpit
      ? "/testing-log/cockpit"
      : "/home";
  activatingClientId.value = id;
  const appUserStore = useAppUserStore();
  await appUserStore.updateAppUserMe({
    activeClientId: id,
  });
  activatingClientId.value = null;
  await router.push({
    path,
    query,
  });
};
</script>
