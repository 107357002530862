<template>
  <div class="">
    <SidebarMobile
      :sidebar-open="sidebarOpen"
      :loading="isLoadingSidebar"
      @close-sidebar="sidebarOpen = false"
    />
    <SidebarDesktop :loading="isLoadingSidebar" />
    <NavbarDesktop />
    <NavbarMobile @open-sidebar="sidebarOpen = true" />
    <main
      :class="[
        hasSecondaryNav ? 'lg:pl-72' : 'lg:pl-24',
        isLoadingPage ? 'opacity-0' : '',
      ]"
    >
      <slot />
    </main>
    <NotificationWrapper />
    <FolderRenameModal
      v-if="folderId"
      :open="openRenameFolderModal"
      :folder-id="folderId"
    />
    <FolderDuplicateModal
      v-if="folderId"
      :open="openDuplicateFolderModal"
      :folder-id="folderId"
      @duplicate:success="onDuplicateFolderSuccess"
    />
    <UsageExceededModal :open="openUsageExceededModal" />
    <PremiumFeatureModal :open="openPremiumFeatureModal" />
    <DuplicatePresetModal
      v-if="presetId"
      :open="mode === 'duplicate-preset'"
      :preset-id="presetId"
    />
    <MetricPresetOptions
      :open="mode === 'preset-options'"
      :preset-id="presetId"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { PaymentPlan, paymentPlantoPaymentPlanIF } from "~/types/Payment";
import { NOT_BLOCKED_PAGES, NO_SIDEBAR_PAGES } from "~/shared/constants";
import DuplicatePresetModal from "~/components/DuplicatePresetModal.vue";

useHead({
  htmlAttrs: {
    class: "h-full bg-gray-50",
  },
  bodyAttrs: {
    class: "h-full",
  },
});

defineProps({
  isLoadingPage: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const router = useRouter();
const sidebarOpen = ref(false);
const isLoadingSidebar = ref(true);
const isMounting = ref(true);
const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const { hasFeature } = useFeatureFlags();
const hasTestingLogReport = hasFeature("testing-log-as-report_2024-03-27");
const hasInspirationBoard = hasFeature("inspiration-board_2024-01-01");
const hasLaunchCockpitFeature = hasFeature("launch-cockpit_2024-07-15");
const hasTaggingFeature = hasFeature("tagging_2024-09-02");
const folderId = computed(() => parseInt(route.query.folderId as string));
const presetId = computed(() => parseInt(route.query.presetId as string));
const folderStructureStore = useFolderStructureStore();
const creativeReportingStore = useCreativeReportingStore();
const creativeComparisonStore = useCreativeComparisonStore();
const inspirationBoardStore = useInspirationBoardStore();
const testingLogStore = useTestingLogStore();
const launchReportStore = useLaunchReportStore();
const clientStore = useClientStore();
const { activeClient } = storeToRefs(clientStore);
const taggingStore = useTaggingStore();

const mode = computed(() => {
  const modeMaybe = route.query.mode as string | undefined | null;
  return modeMaybe == null ? null : modeMaybe;
});

const openRenameFolderModal = computed(() => mode.value === "rename-folder");
const openDuplicateFolderModal = computed(
  () => mode.value === "duplicate-folder",
);
const openUsageExceededModal = computed(() => mode.value === "usage-exceeded");
const openPremiumFeatureModal = computed(
  () => mode.value === "premium-feature",
);

watch(
  () => activeClient.value,
  async (client) => {
    if (client && !isMounting.value) {
      await listClientDependentResources(client.id);
      await showUsageExceededModalMaybe();
    }
  },
);

const { getActiveWorkspace } = useWorkspace();
const activeWorkspace = computed(() => getActiveWorkspace(appUserMe.value));
const paymentPlanInfo = computed(() =>
  activeWorkspace.value != null
    ? paymentPlantoPaymentPlanIF[activeWorkspace.value.plan]
    : null,
);
const currentMonthUsage = computed(() => {
  return activeWorkspace.value?.curMonthUsage ?? 0;
});
const maxAllowedUsage = computed(() =>
  paymentPlanInfo.value != null
    ? paymentPlanInfo.value.restrictions.analyzedAds
    : 0,
);
const isUsageLimitExceeded = computed(
  () =>
    currentMonthUsage.value > maxAllowedUsage.value &&
    maxAllowedUsage.value > 0,
);
const isCustomPlan = computed(
  () => paymentPlanInfo.value?.id === PaymentPlan.CUSTOM,
);

onMounted(async () => {
  isMounting.value = true;
  isLoadingSidebar.value = true;
  await nextTick();
  await appUserStore.listAppUsers();
  isLoadingSidebar.value = false;
  if (activeClient.value) {
    await listClientDependentResources(activeClient.value.id);
  }
  await showUsageExceededModalMaybe();
  listBackgroundResources();
  isMounting.value = false;
});

const listClientDependentResources = async (clientId: number) => {
  const tasks: Array<Promise<any>> = [
    listReportsAndBoardsOfClient(clientId),
    listFoldersOfClient(clientId),
  ];
  if (hasTaggingFeature) {
    tasks.push(taggingStore.listTagsOfClient({ clientId }));
  }
  await Promise.all(tasks);
};

const listReportsAndBoardsOfClient = async (clientId: number) => {
  const tasks = [
    creativeReportingStore.listReports(clientId),
    creativeComparisonStore.listReports(clientId),
  ] as Promise<unknown>[];
  if (hasInspirationBoard) {
    tasks.push(inspirationBoardStore.listBoards(clientId));
  }
  if (hasTestingLogReport) {
    tasks.push(testingLogStore.listReports(clientId));
  }
  if (hasLaunchCockpitFeature) {
    tasks.push(launchReportStore.listReports(clientId));
  }
  await Promise.all(tasks);
};

const listFoldersOfClient = async (clientId: number) => {
  await folderStructureStore.listFoldersOfClient(clientId);
};

const connectionStore = useConnectionStore();

const listBackgroundResources = async () => {
  await Promise.all([connectionStore.listConnections()]);
};

const onDuplicateFolderSuccess = async () => {
  if (activeClient.value == null) {
    return;
  }
  await listReportsAndBoardsOfClient(activeClient.value.id);
};

const showUsageExceededModalMaybe = async () => {
  const isOnAllowedPage = NOT_BLOCKED_PAGES.some((page) =>
    useIsCurrentRoute(page),
  );
  const show =
    !isCustomPlan.value && isUsageLimitExceeded.value && !isOnAllowedPage;
  if (show) {
    await router.push({
      path: route.path,
      query: {
        ...route.query,
        mode: "usage-exceeded",
      },
    });
  }
};

const hasSecondaryNav = computed(
  // Important: Use starts with as includes with /connect would make /settings/connections disappear
  () => !NO_SIDEBAR_PAGES.some((p) => route.path.startsWith(p)),
);
</script>
